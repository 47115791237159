//Define global variable outside the scope of the functions



const uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));

window.Client = {
init: function() {
// Do something on initialization
{ 
           console.log('uuid', uuid);
        document.getElementById('id-3bec00ce').value = uuid;
}
        
},

onRender: function(screenId) {
// Do something when the flow renders
 }
}

